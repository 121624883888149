<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active h-100"
  >
    <!--begin::SubIn-->
    <b-card class="h-100">
      <div class="row justify-content-between align-self-center mb-3">
        <div>
          <h3 class="text-primary mb-1">Informasi</h3>
          <span class="text-muted">Showing 1 to 10 of 59 entries</span>
        </div>
        <div class="row no-gutters" style="gap: 8px">
          <b-form-group class="mb-0">
            <div class="input-icon">
              <input type="text" class="form-control" placeholder="Search..." />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="optionFilter"
            ></b-form-select>
          </b-form-group>
          <b-button
            variant="primary"
            style="height: 38px"
            v-b-modal.modal-information
            >Tambah Informasi</b-button
          >
        </div>
      </div>
      <div
        class="h-100 d-flex pb-8"
        style="flex-direction: column; justify-content: space-between"
      >
        <div class="table-responsive">
          <b-table
            hover
            :items="tableData"
            :fields="fields"
            :per-page="params.limit"
            :current-page="currentPage"
            :busy="isLoading"
            show-empty
          >
            <template #cell(images)="row">
              <img :src="row.item.images[0]?.path" width="50" height="50" />
            </template>
            <template #cell(attribute)="row">
              <b-badge
                class="mr-1"
                variant="secondary"
                v-for="list in row.item.attribute"
                :key="list.id"
                >{{ list }}</b-badge
              >
            </template>
            <template #cell(action)="row">
              <b-button
                variant="success"
                class="py-1 px-2 font-weight-bold"
                id="detail"
                title="proses"
                @click="handleDetail(row.item)"
              >
                <i class="menu-icon flaticon-eye pr-0"></i>
              </b-button>
              <b-button
                variant="primary"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="edit"
                title="edit"
                @click="handleEdit(row.item)"
              >
                <i class="menu-icon flaticon2-edit pr-0"></i>
              </b-button>
              <b-button
                variant="danger"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="delete"
                title="proses"
                @click="handleDelete(row.item)"
              >
                <i class="menu-icon flaticon2-trash pr-0"></i>
              </b-button>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="params.limit"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-pagination
              v-model="refCurrentPage"
              :total-rows="totalCount"
              :per-page="params.limit"
              size="md"
              align="fill"
              class="ml-auto"
              style="padding: 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-information"
      size="lg"
      :title="
        Object.keys(selectedList).length ? 'Edit Informasi' : 'Tambah Informasi'
      "
      @hide="resetModal"
    >
      <form ref="form">
        <b-form-group
          id="input-group-2"
          label="Nama Informasi*"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.title"
            required
            placeholder="Masukan judul disini"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Deskripsi*" label-for="input-2">
          <quill-editor
            ref="myQuillEditor"
            placeholder="Masukan informasi disini"
            v-model="form.content"
            :options="editorOption"
          />
        </b-form-group>
        <b-form-group label="Upload Gambar*" label-for="input-2">
          <DropFile
            :listFile="form.images"
            @imagesChange="imagesChange"
            @imagesRemove="removeImages"
          >
          </DropFile>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="mr-3"
            @click="
              Object.keys(selectedList).length
                ? handleEditSubmit()
                : handleSubmit()
            "
          >
            {{ Object.keys(selectedList).length ? "Update" : "Simpan" }}
          </b-button>
          <b-button variant="secondary" size="md" @click="hideModal">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal hide-footer id="modal-view" size="md" title="Preview Informasi">
      <carousel :perPage="1">
        <slide
          v-for="(image, index) in this.form.images"
          :key="index"
          style="height: 455px"
        >
          <img :src="image?.path" alt="slide image" width="100%" />
        </slide>
      </carousel>
      <h1 class="mt-8 font-weight-bold">
        {{ this.form.title }}
      </h1>
      <div class="mt-10 font-weight-bold">{{ this.form.created_at }}</div>
      <div class="mt-4">
        {{ this.form.content }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";
import DropFile from "@/components/DropFile.vue";

export default {
  name: "InformationPage",
  components: {
    DropFile,
  },
  data() {
    return {
      // Table Req
      selected: null,
      isLoading: false,
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      optionFilter: [
        { value: "asc", text: "Terbaru" },
        { value: "desc", text: "Filter" },
      ],
      fields: [
        {
          key: "images",
          label: "Gambar",
          align: "left",
          sortBy: "",
          width: 50,
          // sortable: true,
        },
        {
          key: "id",
          label: "ID",
          align: "left",
          sortBy: "",
          sortByFormatted: true,
          filterByFormatted: true,
          width: 100,
          // sortable: true,
        },
        {
          key: "title",
          label: "Judul Informasi",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "action",
          align: "center",
          width: 100,
        },
      ],
      form: {
        title: "",
        content: "",
        images: [],
      },
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      tableData: [],
      totalCount: 0,
      params: {
        query: "",
        sort: "content",
        order: "desc",
        limit: 10,
        offset: 0,
      },
      images: [
        "https://picsum.photos/1024/480/?image=10",
        "https://picsum.photos/1024/480/?image=12",
        "https://picsum.photos/1024/480/?image=22",
      ],
      //-----//
      options: ["All Size", "S", "M", "L", "XXL"],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
      selectedList: {},
      tempCompareData: {},
    };
  },
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    paramsComp() {
      return JSON.parse(JSON.stringify(this.params));
    },
  },
  mounted() {
    this.getData();
    // this.totalRows = this.tableData.length;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Infomasi" },
    ]);
  },
  created() {},
  methods: {
    imagesChange(item) {
      this.form.images = item;
      if (Object.keys(this.selectedList).length) {
        const imageUpload = item.filter(
          (item) => item.name.substring(0, 4) !== "http"
        );
        if (imageUpload.length) {
          imageUpload.forEach(async (image) => {
            let formData = new FormData();
            formData.append("news_id", this.selectedList.id);
            formData.append("image", image);
            await this.$axios
              .post(
                `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/image`,
                formData
              )
              .then(() => {
                this.$bvToast.toast("Berhasil menambahkan image!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                this.getDetailData();
              })
              .catch(({ response }) => {
                this.$bvToast.toast(
                  response?.data?.message ??
                    `Terjadi Kesalahan dalam upload ${image.name}`,
                  {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
          });
        }
      }
    },
    removeImages(file, index) {
      const imagesFind = this.selectedList.images.find(
        (item) => item.path === file.name
      );
      if (Object.keys(imagesFind ?? {}).length) {
        this.$swal({
          title: "Hapus Image ini ?",
          showCancelButton: true,
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: `Hapus`,
          heightAuto: false,
        }).then(async (result) => {
          if (result.isDenied) {
            await this.$axios
              .delete(
                `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/image/${imagesFind.id}`
              )
              .then(() => {
                this.$bvToast.toast("Berhasil menghapus image!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                this.getDetailData();
              })
              .catch(({ response }) => {
                this.$bvToast.toast(
                  response?.data?.message ?? "Terjadi Kesalahan",
                  {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
            this.form.images.splice(index, 1);
            return true;
          } else return false;
        });
      } else return false;
    },
    async getDetailData() {
      try {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/${this.selectedList.id}`
          )
          .then(({ data }) => {
            const dataSelected = JSON.parse(JSON.stringify(data));
            this.selectedList = dataSelected;
            this.form = {
              title: dataSelected.title,
              content: dataSelected.content,
              images: [],
            };
            if (data.images.length > 0) {
              this.$nextTick(() => {
                data.images.forEach((item) => {
                  const file = new File([], item.path, { type: "image/png" });
                  this.form.images.push(file);
                });
              });
            }
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    getData: _.debounce(async function () {
      try {
        this.tableData = [];
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news`)
          .then(({ data }) => {
            this.tableData = data.list;
            this.totalCount = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    }, 500),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      if (
        Object.keys(this.selectedList).length &&
        !_.isEqual(this.tempCompareData, this.form)
      ) {
        this.getData();
      }
      this.selectedList = {};
      this.form = {
        title: "",
        content: "",
        images: [],
      };
    },
    hideModal() {
      this.$bvModal.hide("modal-information");
    },
    onRemove(removeTag) {
      const index = this.form.size.findIndex((item) => {
        return item === removeTag;
      });
      if (index !== -1) this.form.size.splice(index, 1);
    },

    handleDetail(detail) {
      this.form = detail;
      this.$bvModal.show("modal-view");
    },
    async handleEdit(detail) {
      this.selectedList = detail;
      this.form = {
        title: detail.title,
        content: detail.content,
        images: [],
      };
      if (detail.images.length > 0) {
        this.$nextTick(() => {
          for (const item of detail.images) {
            const file = new File([], item.path, { type: "image/png" });
            this.form.images.push(file);
          }
          this.tempCompareData = this.form;
        });
      }
      this.$bvModal.show("modal-information");

      // await this.$axios
      //   .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/${detail.id}`)
      //   .then(({ data }) => {
      //     this.selectedList = data;
      //     this.form = data;
      //   });
    },

    handleDelete(item) {
      this.$swal({
        title: "Hapus informasi ini  ?",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: `Hapus`,
        heightAuto: false,
      }).then(async (result) => {
        if (result.isDenied) {
          await this.$axios
            .delete(
              `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/${item.id}`
            )
            .then(() => {
              this.getData();
              this.$swal({
                text: "Berhasil menghapus informasi ini!",
                icon: "success",
                heightAuto: false,
              });
            })
            .catch(() => {
              this.$swal({
                text: "Gagal menghapus informasi ini!",
                icon: "failed",
                heightAuto: false,
              });
            });
        }
      });
    },
    async handleEditSubmit() {
      try {
        let formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("type", "dana-darurat");
        formData.append("status", "published");
        formData.append("content", this.form.content);
        await this.$axios
          .put(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news/${this.selectedList.id}`,
            formData
          )
          .then(() => {
            this.hideModal();
            this.getData();
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async handleSubmit() {
      try {
        let formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("type", "dana-darurat");
        formData.append("status", "published");
        formData.append("content", this.form.content);
        this.form.images.forEach((item) => {
          formData.append("images[]", item);
        });
        await this.$axios
          .post(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/news`, formData)
          .then(() => {
            this.hideModal();
            this.getData();
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.path !== oldValue.path) {
        this.params = {
          query: null,
          sort: "created_at",
          order: "desc",
          limit: 10,
          offset: 0,
        };
        this.getData();
      }
    },
    paramsComp: {
      deep: true,
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue.query !== oldValue.query) {
          this.params.offset = 0;
        }
        this.getData();
      }, 400),
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-status {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 4px 8px;
  color: #89909a;
  width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  gap: 8px;
  i {
    color: #89909a;
  }
}
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
